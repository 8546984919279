<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">

          <v-card-title primary-title>
            <span class="text-subtitle-1">Sueldo Timbrado</span>
            <v-spacer></v-spacer>

            <v-btn
              class="mr-2"
              small
              dark
              color="green"
              @click="exportarDataExcel()"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

          </v-card-title>

          <v-card-text>          
            <v-row >
              <!-- Seleccionar el plantel -->  

              <v-col cols="12" sm="6" md="4" lg="3">
                <v-select
                  :items="['Activos','Inactivos','Todos']"
                  v-model="activos"
                  label="Activos"
                  filled
                  dense
                  single-line
                  hide-details
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3">
                <v-autocomplete
                  :items="puestos"
                  v-model="puesto_seleccionado"
                  label="Puesto"
                  clearable
                  filled
                  dense
                  item-text="puesto"
                  item-value="idpuesto"
                  single-line
                  hide-details
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3">
                <v-autocomplete
                  label="Plantel"
                  id="id"
                  filled
                  dense
                  hide-details
                  v-model="plantel"
                  :items="planteles"
                  item-text="plantel"
                  item-value="id_plantel"
                  clearable
                  single-line
                  hide-details
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="6" lg="5" >
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="filterTrabajadores"
                  class="elevation-0"
                  :search="search"
                  :mobile-breakpoint="100"
                  dense
                >

                  <!-- Valores que ocupan botons, chips, iconos, etc -->
                  <template v-slot:item.activo_sn="{ item }">
                    <v-chip 
                      v-if="item.activo_sn == 1"
                      small
                      color="green"
                      dark
                    >Si</v-chip>

                    <v-chip 
                      v-else
                      small
                      color="error"
                      dark
                    >No</v-chip>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn 
                      color="primary" 
                      x-small
                      tile
                      @click="editItem(item)"
                      class="mr-2"
                    >
                      <v-icon small>mdi-magnify</v-icon>
                    </v-btn>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogo de editar-->
    <v-dialog
      v-model="dialog_editar"
      max-width="1000px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle2 }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
           <strong>ID: {{ editedItem.id_trabajador }}</strong>
         </span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.nombres"
                label="Nombre(s)"
                filled
                hide-details
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.apellido_paterno"
                label="Apellido Paterno"
                filled
                hide-details
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.apellido_materno"
                label="Apellido Materno"
                filled
                hide-details
                dense
                readonly
              ></v-text-field>
            </v-col>
            
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.factor"
                label="Factor"
                filled
                readonly
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.sueldo_mensual"
                label="Sueldo Mensual"
                filled
                hide-details
                dense
                readonly
                auto-grow
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.sueldo_semanal"
                label="Sueldo Semanal"
                filled
                hide-details
                dense
                readonly
                auto-grow
              ></v-text-field>
            </v-col>
            
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.sueldo_dia"
                label="Sueldo Día"
                readonly
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.sueldo_timbrado"
                label="Sueldo timbrado"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="(( editedItem.sueldo_dia * 7 ) - editedItem.sueldo_timbrado ).toFixed( 2 )"
                label="Excedente"
                filled
                hide-details
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.cuenta_hsbc"
                label="Cuenta"
                filled
                hide-details
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-radio-group
                v-model="editedItem.escuela"
                row
              >
                <v-radio
                  label="INBI"
                  :value="1"
                ></v-radio>
                <v-radio
                  label="FAST"
                  :value="2"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              
            </v-col>
            <v-col cols="12" md="6">
              
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="save"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo contraseña incorrecta -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row justify="space-around">
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
                  <strong>Contraseña Incorrecta</strong> <br>Favor de revisar que ambas contraseñas sean iguales.</br>
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" text  class="mr-2" @click="closeDialog()">Cerrar</v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-img src="@/assets/eliminar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row justify="space-around">
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
                    <!-- Guardar la información  -->
                    <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-img src="@/assets/borrar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogVerDocumentos"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Documentos firmados
        </v-card-title>
        <v-card-text>
          <v-checkbox
            v-model="reglamento"
            readonly
            label="Reglamento"
          ></v-checkbox>

          <v-checkbox
            v-model="descriptor"
            readonly
            label="Descriptor"
          ></v-checkbox>

          <v-checkbox
            v-model="misionvision"
            readonly
            label="Misión Visión"
          ></v-checkbox>

          <v-checkbox
            v-model="confidencialidad"
            readonly
            label="Confidencialidad"
          ></v-checkbox>
        </v-card-text>
      </v-card>     
    </v-dialog>

    <Sueldos
      v-if="dialogSueldos.estatus"
      :dialogSueldos="dialogSueldos"
      @getSueldos="getSueldos"
    />

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import Sueldos               from '@/components/nomina/Sueldos.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

    components:{
      Alerta,
      carga,
      Sueldos
    },

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      activos: null,
      puestos:[],
      puesto_seleccionado: null,
      plantel: null,
      planteles:[],
      perfiles:[],
      puestos:[],
      jornada:[],
      tipo_trabajador:[],
      nivel:[],
      curso:[],
      trabajadores:[],
      trabajadorERP:[],
      usua:[],
      departamento:[],
      valor:0,

      fecha_nacimiento:0,
      fecha_termino_labores:null,
      fecha_ingreso:0,

      dialog_agregar: false,
      dialog_editar: false,
      dialogDelete: false,
      dialog: false,
      
      editedIndex: -1,

      dialogSueldos: {
        estatus: false
      },

      editedItem: {
        id:null,
        sueldo_timbrado: 0,
        excedente:0,
        escuela:0,
        sueldo_semanal:0,
        cuenta_hscb:'',
      },

      defaultItem: {
        id:null,
        sueldo_timbrado: 0,
        excedente:0,
        escuela:0,
        sueldo_semanal:0,
        cuenta_hscb:'',
      },

      baseItem: {
        id:null,
        sueldo_timbrado: 0,
        excedente:0,
        cuenta_hscb:'',
     },

     categoriasVendedoras:[],

      // Filtros: 
     incio:null,
     fin:null,

      // tabla
     search:'',
     page: 1,
     pageCount: 0,
     itemsPerPage: 10,
     horarios: [],
     headers: [
      { text: 'ID'                    , value: 'id_trabajador'   },
      { text: 'Trabajador'            , value: 'nombre_completo' },
      { text: 'Puesto'                , value: 'puesto'          },
      { text: 'Depto'                 , value: 'departamento'    },
      { text: 'Sucursal'              , value: 'plantel'         },
      { text: 'S. Timbrado'           , value: 'sueldo_timbrado' },
      { text: 'Excedente'             , value: 'Excedente'       },
      { text: 'Acciones'              , value: 'actions', sortable: false },
    ],

      usuarios: [],
      disponibilidades:[],
      categorias:[],
      sueldos: [],
      vacantes:[],
      reglamento: false,
      descriptor: false,
      misionvision: false,
      confidencialidad: false,
      dialogVerDocumentos: false,
      files:'',

      url:'',
      opComentario : null,

    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),

      formTitle () {
        return 'Agregar Usuario'
      },

      formTitle2 () {
        return 'Editar Usuario'
      },

      filterTrabajadores( ){
        let data = this.trabajadorERP

        if( this.plantel ){
          data = data.filter( el=> { return el.id_plantel == this.plantel })
        }

        if( this.activos == 'Activos'){
          data = data.filter( el=> { return el.activo_sn == 1 })
        }

        if( this.activos == 'Inactivos'){
          data = data.filter( el=> { return el.activo_sn == 0 })
        }

        if( this.puesto_seleccionado ){
          data = data.filter( el=> { return el.idpuesto == this.puesto_seleccionado })
        }

        if( this.opComentario ){

          data = data.filter( el=> { return el.notas_especiales })

        }

        return data 
      },

    },

    watch: {
      dialog_agregar (val) {
        val || this.close()
      },
      dialog_editar (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      "editedItem.factor"( val ){
        if( this.editedItem.sueldo_mensual || this.editedItem.idsueldo_recepcion){
          if( this.editedItem.idpuesto == 19 ){
            // Si el puesto es 19, se hace algo diferente, ya que tiene una categoría
            if( this.editedItem.idsueldo_recepcion ){

              const sueldo = this.sueldos.find( el => el.idsueldo_recepcion == this.editedItem.idsueldo_recepcion )

              if( sueldo ){

                this.editedItem.sueldo_dia     = ( sueldo.sueldo_mensual / val ).toFixed( 2 )
                this.editedItem.sueldo_hora    = ( ( sueldo.sueldo_mensual / val ) / 8 ).toFixed( 2 )

              }

            }

          }else{
            this.editedItem.sueldo_mensual = this.editedItem.sueldo_mensual
            this.editedItem.sueldo_dia     = ( this.editedItem.sueldo_mensual / this.editedItem.factor ).toFixed( 2 )
            this.editedItem.sueldo_hora    = ( ( this.editedItem.sueldo_mensual / this.editedItem.factor ) / 8 )
            this.editedItem.sueldo_semanal = this.editedItem.sueldo_dia * 7 
          }
        }
      },

      "editedItem.nombres"( value ){
        this.editedItem.nombres = value.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      },

      "editedItem.apellido_paterno"( value ){
        this.editedItem.apellido_paterno = value.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      },

      "editedItem.apellido_materno"( value ){
        this.editedItem.apellido_materno = value.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      },

    },

    async created () {

      this.url = axios.defaults.baseURL  + 'comprobantes-imss/'
      await this.getUsuarios()
      await this.initialize()
      await this.getPlanteles()
      await this.getPuestos()
    },

    methods: {
      initialize () {  
        this.cargar = true
        this.trabajadorERP = []
        return this.$http.get('trabajadores.erp.get.trabajadoreserp').then(response=>{
          this.trabajadorERP = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      save () {   
        this.$http.post('trabajadores.timbrado', this.editedItem).then(response=>{
          this.dialog_agregar = null,
          this.validarSuccess( response.data.message )
          this.close()
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })  
      },

      getUsuarios( ){
        this.cargar = true
        this.usuarios = []
        this.$http.get('usuarios.all.erp').then(response=>{
          this.usuarios = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
        this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
          this.planteles = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPuestos () {
        this.puestos = []
        this.$http.get('puestos.all').then(response=>{
          this.cargar = false
          this.puestos = response.data
          // Traer los puestos
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.horarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog_editar = true
      },

      deleteItem (item) {
        this.editedIndex = this.horarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('horarios.eliminar/' + this.editedItem.id_horario, this.editedItem).then(response=>{
          this.cargar = true
          this.closeDelete()
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close() {
        this.dialog_editar = false
        this.dialog_agregar = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.baseItem)
          this.defaultItem = Object.assign({}, this.baseItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.baseItem)
          this.editedIndex = -1
        })
      },

      closeDialog () {
        this.dialog = false
      },

      exportarDataExcel( ){
        this.exportExcel( this.filterTrabajadores, 'Trabajadores')
      }
    },
  }
</script>

